<template>
  <div class="editor">
    <div class="title">
      <img src="@/assets/icons/user-yellow.svg" alt="">
      <span>{{ $t('ai_editor')/*設定人*/ }}</span>
    </div>
    <div v-if="user" class="content">
      <div class="group-name">
        <img src="@/assets/icons/Group-yellow.svg" alt="">
        <span>{{ groupName }}</span>
      </div>
      <span class="user-name">
        {{ userName }} ({{ accountId }})
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Editor',
  props: ['userId'],
  data() {
    return {}
  },
  computed: {
    ...mapState('account', ['userList', 'deviceList', 'groupList']),
    user() {
      const editor = this.userList.find(user => user.index === Number(this.userId))
      if (editor) return editor
      // If the user is not found, return device account
      return this.deviceList.find(user => user.index === Number(this.userId))
    },
    groupName() {
      return this.user ? this.groupList.find(group => group.id === this.user.groupId).name : ''
    },
    userName() {
      return this.user ? this.user.info.name : ''
    },
    accountId() {
      return this.user ? this.user.id : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  background: #39425D;
  border-radius: 8px;

  .title {
    display: flex;
    align-items: center;
    @include font_style(px2rem(16), px2rem(24), #FFE99F, 700);
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      @include filter_FFE99F;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
  }

  .group-name {
    display: flex;
    align-items: center;
    color: #FFC600;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .user-name {
    word-break: break-all;
    color: #ffffff;
  }
}
</style>